import { createStore } from 'vuex'
import imgVid from './imgVid.js'
import login from './login.js'
import config from './config.js'
import creatVid from './creatVid.js'
import shareArr from './shareArr.js'
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {

  },
  actions: {
  },
  modules: {
    imgVid,
    login,config,creatVid,shareArr
  }
})


