<template>
  <div class="login-wrap" v-show="isShowLogin">
    <div class="content">
      <div class="login-top">
        <div
          class="title"
          v-text="tabData == 4 ? '註冊你的超剪用戶' : '登錄你的超剪用戶'"
        ></div>
        <div class="close" @click="closeBtn()">X</div>
      </div>
      <div class="login-tip" v-if="false">
        <span>{{ tabData == 4 ? "已有賬戶?" : "沒有賬戶?" }}</span>
        <span
          @click="togggleLoginPage(tabData == 4 ? '2' : '4')"
          class="tip-tit"
          ref=""
          >{{ tabData == 4 ? "前去登錄" : "免費註冊" }}
          <span
            style="margin: 2px 0 0 0px; font-size: 13px; color: #728e9d"
            class="iconfont icon-xiayiyehouyiye"
          ></span>
        </span>
      </div>
      <div class="form-wrap">
        <div class="login-mode" v-if="tabData != 4">
          <div
            class="mode-tab"
            @click="tabToggleBtn('2')"
            :class="{ 'color-tab': tabData == 2 }"
          >
            郵箱驗證碼登錄
          </div>
          <div
            class="mode-tab"
            @click="tabToggleBtn('1')"
            :class="{ 'color-tab': tabData == 1 }"
          >
            手機驗證碼登錄
          </div>
        </div>
        <!-- 4註冊 -->
        <el-form
          ref="ruleFormRef"
          :rules="rules"
          size="large"
          :model="formModel"
          style="max-width: 460px"
        >
          <el-form-item prop="phone" v-if="tabData != 2">
            <el-input v-model="formModel.phone" placeholder="輸入手機號">
              <template #prepend>
                <el-select
                  v-model="formModel.area"
                  placeholder="地區"
                  style="width: 115px"
                >
                  <el-option label="大陸" value="1" />
                  <el-option label="香港" value="2" />
                </el-select>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="mailbox" v-if="tabData != 1">
            <el-input v-model="formModel.mailbox" placeholder="輸入電郵地址" />
          </el-form-item>
          <el-form-item prop="codes">
            <div class="input-code-wrap">
              <el-input
                v-model.number="formModel.codes"
                placeholder="輸入電郵驗證碼"
              >
              </el-input>
              <el-button
                :disabled="isCounting"
                :loading="isCounting || IsState"
                @click="handleThrottledScroll()"
                text
                size="small"
                class="codes-css"
              >
                <span v-if="IsState"> 請求中...</span>
                <span v-else>
                  {{ isCounting ? countdownText : "获取验证码" }}</span
                >
              </el-button>
            </div>
          </el-form-item>
          <el-form-item prop="uid" v-if="tabData == 4">
            <el-input v-model.number="formModel.uid" placeholder="輸入推薦碼">
            </el-input>
          </el-form-item>
          <div class="protocol-wrap">
            <span>登錄即代表同意</span>
            <span class="protocol-data" @click="protocolBTn(1)"
              >《用戶協議》</span
            >
            <span class="protocol-data" @click="protocolBTn(2)"
              >《隱私協議》</span
            >
            <span class="protocol-data" @click="protocolBTn(5)"
              >《付費協議》</span
            >
          </div>
          <el-form-item>
            <el-button
              color="#2C3239"
              class="sub-btn"
              type="primary"
              :loading="IsloginLoad"
              @click="onSubmitDebouncedClick"
              >{{ tabData == 4 ? "註冊" : "登錄" }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
  <script>
import { ref, onBeforeUnmount, reactive, watch, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { ElLoading, ElMessageBox, ElMessage } from "element-plus"; //
import { debounce, throttle } from "lodash";
import { userInfo } from "@/api/login";
import { checkVipExpiry } from "@/api/packFun";
import {
  getCode,
  userRegister,
  userPhoneLogin,
  userMailboxLogin,
} from "@/api/login";
export default {
  name: "Login",
  props: {
    loginShow: Boolean,
  },
  emits: ["update:loginShow"],

  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    let isShowLogin = ref(props.loginShow); // 初始化为 props.loginShow 的值
    // 监听 props.loginShow 的变化，并更新 isShowLogin
    watch(
      () => props.loginShow,
      (newValue) => {
        isShowLogin.value = newValue;
      }
    );
    const closeBtn = () => {
      isShowLogin.value = false;
      emit("update:loginShow", false);
      emptyData();
    };

    const tabData = ref("2"); //1手机登录  2邮箱登录    4注册
    //登錄注冊參數
    const formModel = reactive({
      phone: "", //手機號64213436
      mailbox: "", //郵箱8205653812@qq.com
      codes: "", //驗證碼133058
      uid: "", //推广码0
      area: 2, //手机区号  默认0  ， 1国内  2香港
    });
    const ruleFormRef = ref(null);

    const validatePhone = (rule, value, callback) => {
      const mainlandRegex = /^1(3|4|5|6|7|8|9)\d{9}$/;
      const hongkongRegex = /^(5|6|8|9)\d{7,8}$/; // 假设用户只输入香港本地号码部分
      if (formModel.area === "1" && mainlandRegex.test(value)) {
        callback();
      } else if (formModel.area === "2" && hongkongRegex.test(value)) {
        //这是香港号码
        callback();
      } else {
        callback(new Error("请输入有效的手机号"));
      }
    };

    const rules = {
      codes: [
        { required: true, message: "請輸入驗證碼", trigger: "blur" },
        { type: "number", message: "驗證碼為數字" },
        // { min: 3, max: 10, message: "驗證碼應為4位數", trigger: "blur" },
      ],
      mailbox: [
        {
          required: true,
          message: "請輸入電郵",
          trigger: "blur",
        },
        {
          type: "email",
          message: "請輸入正確的電郵",
          trigger: ["blur", "change"],
        },
      ],
      phone: [
        { required: true, message: "請輸入手機號", trigger: "blur" },
        { validator: validatePhone, trigger: "blur" },
      ],
    };
    const IsloginLoad = ref(false);
    const IsState = ref(false);
    // 控制验证码发送按钮是否禁用和是否显示加载状态
    const isCounting = ref(false);
    // 倒计时时间，这里设置为 60 秒
    const countdown = ref(60);
    // 用于显示倒计时文本的字符串
    const countdownText = computed(() => {
      return countdown.value > 0
        ? `${countdown.value}秒后重新获取`
        : "获取验证码";
    });
    const startCountdown = () => {
      const timer = setInterval(() => {
        if (countdown.value <= 1) {
          clearInterval(timer);
          isCounting.value = false;
          countdown.value = 60;
        } else {
          countdown.value--;
        }
      }, 1000);
    };
    //獲取驗證碼
    const getCodeBtn = async () => {
      try {
        if (isCounting.value) return; // 如果正在倒计时，则不执行
        if (formModel.phone == "" && tabData.value == 1) {
          ElMessage({
            type: "error",
            message: "手機號不能為空",
          });
          return;
        }
        if (formModel.mailbox == "" && tabData.value == 2) {
          ElMessage({
            type: "error",
            message: "電郵不能為空",
          });
          return;
        }
        // ElMessage({
        //   type: "text",
        //   message: "验证码发送中",
        // });
        IsState.value = true;
        let { code, msg } = await getCode({
          type: tabData.value, //1手机登录  2邮箱登录    4注册
          mailbox: formModel.mailbox,
          phone: formModel.phone,
          area: formModel.area, //手机区号  默认0  ， 1国内  2香港
        });

        if (code == 200) {
          IsState.value = false;
          isCounting.value = true;
          startCountdown();
        } else {
          IsState.value = false;
        }
      } catch (e) {
        IsState.value = false;
      }
    };
    // 使用节流
    const handleThrottledScroll = throttle(() => {
      getCodeBtn();
    }, 500); // 每100毫秒最多执行一次

    onBeforeUnmount(() => {
      if (countdown.value > 0) {
        clearInterval(); // 注意：这里需要维护一个引用到定时器的变量
      }
    });

    const setRegisterLogin = async () => {
      try {
        let messageTxt = "";
        let res;
        IsloginLoad.value = true;
        if (tabData.value == 4) {
          //註冊郵箱
          res = await userRegister({
            codes: formModel.codes,
            mailbox: formModel.mailbox,
            phone: formModel.phone,
            uid: formModel.uid, //推广码
          });
          messageTxt = "注册";
        } else if (tabData.value == 2) {
          //郵箱登錄
          res = await userMailboxLogin({
            codes: formModel.codes,
            mailbox: formModel.mailbox,
          });
          messageTxt = "郵箱登录";
        } else if (tabData.value == 1) {
          //手機號登錄
          res = await userPhoneLogin({
            codes: formModel.codes,
            phone: formModel.phone,
          });
          messageTxt = "手机登录";
        }
        if (res.code == 200) {
          /* ElMessage({
            type: "success",
            message: `${messageTxt}成功`,
          });
          IsloginLoad.value = false;
          if (tabData.value == 1 || tabData.value == 2) {
            closeBtn();
          }
          return; */
          let response = await userInfo();
          if (response.code === 200 && response.conten) {
            let user = response.conten[0];
            let { txtTip, isExpire } = await checkVipExpiry(user.pcviptimes);
            if (response.conten[0].pcviptype == 0 || isExpire) {
              let title = "";
              if (response.conten[0].pcviptype == 0) {
                title = "非會員賬戶，無使用權限";
              } else {
                title = "會員已到期，無使用權限";
              }
              let tableHTML = `
                           <div class="contact-info">Whatsapp: +852-6241-3436</div>
                           <div class="contact-info">Email: a.plus.a.cut@gmail.com</div>`;
              //過期或普通用戶
              ElMessageBox.alert(tableHTML, title, {
                type: "error",
                showClose: false,
                confirmButtonText: "確定",
                dangerouslyUseHTMLString: true,
              });
              localStorage.removeItem("token");
            } else {
              //有權限的用戶

              ElMessage({
                type: "success",
                message: `${messageTxt}成功`,
              });
            }
            IsloginLoad.value = false;
            if (tabData.value == 1 || tabData.value == 2) {
              closeBtn();
            }
          }
        }
      } catch (e) {
        IsloginLoad.value = false;
      }
    };

    const onSubmit = async () => {
      try {
        await ruleFormRef.value.validate(async (valid) => {
          if (valid) {
            await setRegisterLogin();
          } else {
            return false;
          }
        });
      } catch (e) {
        console.error("验证过程出错", e);
      }
    };
    const onSubmitDebouncedClick = debounce(() => {
      onSubmit();
    }, 1000);

    //清空表单数据
    const emptyData = () => {
      Object.keys(formModel).forEach((key) => {
        formModel[key] = ""; // 或者设置为初始值
        formModel["area"] = "2";
        if (ruleFormRef.value) {
          ruleFormRef.value.resetFields(); // 重置表单并清除验证
        }
      });
    };
    //郵箱還是手機號的登陸
    const tabToggleBtn = (type) => {
      emptyData();
      tabData.value = type;
    };
    //注冊和登錄切換
    const togggleLoginPage = (type) => {
      emptyData();
      tabData.value = type;
    };
    const protocolBTn = (id) => {
      try {
        isShowLogin.value = false;
        emit("update:loginShow", false);
        router.push({
          path: "/home/MoreAgreement",
          query: {
            id: id,
          },
        });
        store.dispatch("login/UPDATEROUTERPATH", "/home/MoreAgreement");
      } catch (e) {}
    };

    return {
      protocolBTn,
      formModel,
      rules,
      ruleFormRef,
      isCounting,
      countdownText,
      onSubmitDebouncedClick,
      isShowLogin,
      closeBtn,
      tabData,
      tabToggleBtn,
      togggleLoginPage,
      handleThrottledScroll,
      IsState,
      IsloginLoad,
    };
  },
};
</script>
  <style lang="scss" scoped>
.login-wrap {
  width: 100%;
  height: 100%;

  background: rgb(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 0;
  .content {
    width: 460px;
    height: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 99;

    padding: 0 20px;
    // background: linear-gradient(
    //   to right,
    //   rgb(75, 84, 87),
    //   rgb(97, 77, 83),
    //   rgb(115, 145, 161)
    // );
    background: var(--bg-color-nine);
    .login-top {
      padding-top: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      height: 62px;
      line-height: 40px;
      flex-shrink: 0;

      .close {
        flex-shrink: 0;
        font-size: 22px;
        cursor: pointer;
        margin-top: -20px;
        color: #fff;
      }
      .title {
        flex-grow: 1;
        font-size: 26px;
        color: #fff;
      }
    }
    .login-tip {
      width: 100%;
      text-align: left;
      // color: #cccccc;
      color: var(--menu-color);
      font-size: 12px;
      height: 20px;
      line-height: 20px;
      .tip-tit {
        font-size: 14px;
        margin-left: 10px;
        cursor: pointer;
        // background: linear-gradient(
        //   to right,
        //   rgb(115, 145, 161),
        //   rgb(75, 84, 87),
        //   rgb(97, 77, 83)
        // );
        background: var(--font-color-four);
        filter: brightness(2);
        -webkit-background-clip: text;
        background-clip: text;
        font-weight: 600;
        color: transparent;
      }
    }

    .form-wrap {
      width: 100%;
      flex-grow: 1;
      background: rgb(255, 255, 255, 1);
      border-radius: 10px;
      margin: 10px 40px 30px;
      .login-mode {
        height: 40px;
        margin: 20px 30px 0;
        display: flex;
        align-items: center;
        justify-content: start;
        color: #666;
        box-sizing: border-box;

        .mode-tab {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          padding: 0 8px;
          border-bottom: 1px solid #a19e9e;
          cursor: pointer;
        }
        .color-tab {
          color: #333;
          font-weight: 600;
          border-bottom: 1px solid #37414d;
          z-index: 10;
        }
      }

      .el-form {
        flex-shrink: 0;
        padding: 26px 30px 40px;
        .sub-btn {
          width: 100%;
        }
        .input-code-wrap {
          width: 100%;
          position: relative;
          .codes-css {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            color: #666;
            font-weight: 500;
          }
          .codes-css:hover {
            background: #fff;
          }
        }
        .protocol-wrap {
          color: #666;
          font-size: 12px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          .protocol-data {
            color: #409eff;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>