import { createI18n } from 'vue-i18n';
import zhCN from '@/locales/zh-CN.js';
import zhTW from '@/locales/zh-TW.js';
import en from '@/locales/en.js';
const i18n = createI18n({
    locale: 'zh-CN', // 默认语言设置为中文简体，可以根据需求调整初始值
    messages: {
        'zh-CN': zhCN,
        'zh-TW': zhTW,
        en
    }
});

export default i18n;