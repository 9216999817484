import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
const routes = [
  { path: "/", redirect: "/home/firstPage" },
  { path: '/login', name: 'Login', component: () => import('../views/Login/Login.vue') },
  { path: '/creatVideo', name: 'CreatVideo', component: () => import('../views/CreatVideo/CreatVideo.vue') },
  { path: '/editVideo', name: 'EditVideo', component: () => import('../views/CreatVideo/EditVideo.vue') },
  {
    path: '/home', name: 'Home', component: () => import('../views/Home/Home.vue'),
    children: [
      { path: 'firstPage', name: 'FirstPage', component: () => import('../views/Home/FirstPage.vue') },
      //定制数字人
      { path: "photosdigitalman", name: "PhotosDigitalMan", component: () => import('../views/CustomizationManage/PhotosDigitalMan.vue') },
      { path: "videodigitalman", name: "VideoDigitalMan", component: () => import('../views/CustomizationManage/VideoDigitalMan.vue') },
      { path: "textlanguage", name: "TextLanguage", component: () => import('../views/CustomizationManage/TextLanguage.vue') },
      { path: "video", name: "Video", component: () => import('../views/CustomizationManage/Video.vue') },
      //模板中心
      { path: "modelcenter", name: "ModelCenter", component: () => import('../views/OverviewManage/ModelCenter.vue') },
      //超剪广场
      { path: "digitalman", name: "DigitalMan", component: () => import('../views/PlazaManage/DigitalMan.vue') },
      //我的管理
      { path: "myaccount", name: "MyAccount", component: () => import('../views/AccountManage/MyAccount.vue') },
      //AI聊天
      { path: "aichat", name: "AiChat", component: () => import('../views/Ability/AiChat.vue') },
      //AI畫圖
      { path: "aidraw", name: "Aidraw", component: () => import('../views/Ability/Aidraw.vue') },
      //AI摳圖
      { path: "aicutout", name: "Aicutout", component: () => import('../views/Ability/Aicutout.vue') },
      //换脸
      { path: "facechang", name: "FaceChang", component: () => import('../views/Ability/FaceChang.vue') },
      { path: "moreagreement", name: "MoreAgreement", component: () => import('../views/AccountManage/MoreAgreement.vue') },
      { path: "message", name: "Message", component: () => import('../views/AccountManage/Message.vue') },
      //定制數字人
      { path: "myvideos", name: "MyVideos", component: () => import('../views/OverviewManage/MyVideos.vue') },
      // { path: "mysound", name: "MySound", component: () => import('../views/OverviewManage/MySound.vue') },
      { path: "customizedimg", name: "CustomizedImg", component: () => import('../views/OverviewManage/CustomizedImg.vue') },
      { path: "customizedsound", name: "CustomizedSound", component: () => import('../views/OverviewManage/CustomizedSound.vue') },
      { path: "myvideosasound", name: "MyVideosAsound", component: () => import('../views/OverviewManage/MyVideosAsound.vue') },
      { path: "mysound", name: "MySound", component: () => import('../views/OverviewManage/MySound.vue') },
      /* 以下暂时不用 */
      // 总览
      { path: "information", name: "Information", component: () => import('../views/OverviewManage/Information.vue') },

      //  视频广场
      { path: "audio", name: "Audio", component: () => import('../views/PlazaManage/Audio.vue') },
      { path: "photos", name: "Photos", component: () => import('../views/PlazaManage/Photos.vue') },

    ]
  }
];


const router = createRouter({
  history: createWebHashHistory(),
  routes
})


export default router





