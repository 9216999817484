export default {
  namespaced: true,
  state: () => ({
    imageData: null,
    soundDataId: '',
    textareaModel: '',
    volObj: {
      volume: 50, //音量
      speechrate: 0, //语速
      pitchrate: 0, //语调
    },
    //数字人
    vidimageDataObj :{
      vidimageData:'',
      vidData:''
    },
    //制定數據
    assignImg:{
      imageData:false,
      VidDatata:false,
      SoundData:false,
    }
  }),
  mutations: {
    setAssignImg(state, params) {
      state.assignImg = {...state.assignImg,...params};
    },

    setImageData(state, img) {
      state.imageData = img;
    },
    setVidData(state, vid) {
      state.soundDataId = vid;
    },
    TextareContentData(state, TextC) {
      state.textareaModel = TextC;

    },
    setVolObjData(state, Vol) {
      state.volObj = {...Vol }; 
    },
    //数字人
    setVidimageData(state, vidimageDataObj) {
      state.vidimageDataObj = { ...state.vidimageDataObj,...vidimageDataObj }; 
    }

  },
  actions: {
    updateImageData({ commit }, img) {
      commit('setImageData', img);
    },
    updateVidData({ commit }, vid) {  //声音id
      commit('setVidData', vid);
    },
    updateTextareContentData({ commit }, TextC) {
      commit('TextareContentData', TextC);
    },
    updateVolObjData({ commit }, Vol) { 
      commit('setVolObjData', Vol);
    },
    //生成数字人数据
    // updateVidPicData({ commit }, { vidimageData, vidData }){
      updateVidPicData({ commit }, vidimageDataObj){
      // console.log(vidimageDataObj,'shhhhhhhhhhhhhhh')
      commit('setVidimageData', vidimageDataObj);
    }
  },
  getters: {
    imageData: (state) => state.imageData,
    soundDataId: (state) => state.soundDataId,
    textareaModel: (state) => state.textareaModel,
    volObj: (state) => state.volObj,
    vidimageDataObj: (state) => state.vidimageDataObj,
    assignImg:(state) => state.assignImg,
  },
};