import { createApp } from 'vue'
import '../src/assets/assets.css'
import '../src/assets/common.css'
import '../src/assets/element.css' 
import '../src/assets/emptyIcon.css'
import '../src/static/font_icon/iconfont.css';

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import 'element-plus/theme-chalk/index.css'
// import 'element-plus/theme-chalk/dark/css-vars.css'

import i18n  from '../src/utils/i18n.js';

import App from './App.vue'
import router from './router'
import store from './store'

import Login from '../src/components/Login.vue'

import VueLazyload from 'vue3-lazyload'
import 'es-drager/lib/style.css'
import Drager from 'es-drager'


const options = {
  preLoad: 1.3,
  error: '',  //dist/error.png
  loading: '',  //dist/loading.gif
  attempt: 1
}
createApp(App).component('Login', Login).component('es-drager', Drager).use(ElementPlus).use(i18n).use(VueLazyload, options).use(store).use(router).mount('#app')
