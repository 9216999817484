<template>
  <router-view />
</template>

<style lang="scss">
:root {
  --page-bg-color: linear-gradient(
    45deg,
    rgb(22, 28, 39),
    rgb(22, 24, 35),
    rgb(22, 27, 38)
  );
  --header-bg-color: linear-gradient(
    45deg,
    rgb(22, 25, 36),
    rgb(22, 28, 39),
    rgb(22, 24, 35),
    rgb(22, 28, 39),
    rgb(22, 27, 38)
  );

  --menu-bg-color: #2d333e;
  --menu-left-bg-color: transparent;
  --menu-left-border-color: #1f222e;
  --menu-color: #e8e8e8; //ccc d1c7c7
  --menu-hover-color: #fff; //ccc d1c7c7

  --menu1-bg-color: #2d333e;
  --menu1-hover-color: #fff;

  // 製作影片
  --creation-bg-color: linear-gradient(
    45deg,
    rgb(52, 117, 153),
    rgb(20, 59, 71),
    rgb(22, 25, 36),
    rgb(77, 7, 28),
    rgb(54, 38, 65),
    rgb(81, 55, 96)
  );
  //
  --popover-bg-color: #2d3542;
  --letter-b: #fff;
  /* 字體 */
  --font-color: #fff;
  --font-color-two: #ddd;
  --font-color-three: #bebebe;
  --font-color-four: linear-gradient(
    to right,
    rgb(115, 145, 161),
    rgb(75, 84, 87),
    rgb(97, 77, 83)
  );
  --font-color-five: #f0f0f0;
  --font-color-six: #e2b149;
  --font-color-seven: #2d333e;
  --font-color-eight: #ccc;
  --font-color-nine: #eee;

  /* 背景 */
  --btn-color: #161924;

  --bg-color: #161823;
  --bg-color-two: #282c3a;
  --bg-color-three: linear-gradient(
    60deg,
    rgb(36, 51, 63),
    rgb(20, 33, 44),
    rgb(3, 6, 12)
  );
  --bg-color-four: linear-gradient(
    to bottom,
    rgb(45, 51, 62),
    rgb(38, 47, 57),
    rgb(22, 25, 36),
    rgb(45, 51, 62)
  );

  --bg-color-five: linear-gradient(
    to bottom,
    rgb(45, 51, 62),
    rgb(38, 47, 57),
    rgb(22, 25, 36),
    rgb(45, 51, 62)
  );
  --bg-color-six: #353d49;
  --bg-color-seven: #353d49;
  --bg-color-eight: linear-gradient(to right, rgb(75, 84, 87), rgb(75, 84, 87));
  --bg-color-nine: linear-gradient(
    to right,
    rgb(75, 84, 87),
    rgb(97, 77, 83),
    rgb(115, 145, 161)
  );
  --bg-color-ten: transparent;
  --bg-color-eleven: #161823;
  --bg-color-twelve: transparent;
  --bg-color-thirteen: #f2f2f2;
  --bg-color-fourteen: #2D333E;
  --border-color: #ccc;
  --border-color-two:#fff;
  --header-height:72px;
  --header-margin:0px;
}
.dark-mode {
  --page-bg-color: #fff;
  --header-bg-color: #fff;

  --menu-bg-color: #e6e9ef;
  --menu-left-border-color: #c8e0f0;
  --menu-color: #606266; //
  --menu-hover-color: #333; //ccc d1c7c7
  --menu-left-bg-color: #d9e4eb;
  // 左侧栏
  --menu1-bg-color: #c8e0f0;
  --menu1-hover-color: #4095cd;
  // 製作影片
  --creation-bg-color: #d9e4eb;
  --creation-bg-color: linear-gradient(
    to right,
    rgb(201, 205, 212),
    rgb(152, 177, 190),
    rgb(175, 198, 211),
    rgb(201, 205, 212)
  );
  --popover-bg-color: #e4e3e2;
  --letter-b: #303133;
  /* 字體 */
  --font-color: #333;
  --font-color-two: #666;
  --font-color-three: #606266;
  --font-color-four: linear-gradient(
    to right,
    rgb(19, 65, 90),
    rgb(33, 65, 114),
    rgb(51, 113, 172)
  );
  --font-color-five: #666;
  --font-color-six: #e0a932;
  --font-color-seven: #fff;
  --font-color-eight: #666;
  --font-color-nine: #888;

  --btn-color: transparent;
  /* 背景 */
  --bg-color: #fff;
  --bg-color-two: transparent;
  --bg-color-three: linear-gradient(
    to bottom,
    rgb(61, 94, 112),
    rgb(43, 59, 83),
    rgb(76, 106, 134)
  );
  --bg-color-four: linear-gradient(
    to right,
    rgb(250, 250, 250),
    rgb(249, 249, 250),
    rgb(250, 250, 250)
  );
  --bg-color-five: linear-gradient(
    to right,
    rgb(250, 250, 250),
    rgb(249, 249, 250),
    rgb(250, 250, 250)
  );
  --bg-color-six: rgb(216, 228, 250);
  --bg-color-seven: #bfced8;
  --bg-color-eight: #fff;
  --bg-color-nine: linear-gradient(
    to bottom,
    rgb(193, 204, 224),
    rgb(140, 140, 141),
    rgb(125, 148, 160),
    rgb(193, 204, 224)
  );
  --bg-color-ten: #f5f5f5;
  --bg-color-eleven: #e6e9ef;
  --bg-color-twelve: #fff;
  --bg-color-thirteen: #666;
  --bg-color-fourteen: #fff;


  --border-color: #d9d9d9;
  --border-color-two:#b7d0f8;
  --header-height:60px;
  --header-margin:16px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

html,
body {
  font-family: "PingFang SC", "Helvetica Neue", "Helvetica", "Arial";
  width: 100%;
  height: 100%;
  color: var(--font-color);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  overflow-y: hidden;
}
</style>
