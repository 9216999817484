import request from '@/utils/request'
// 获取验证码
export function getCode(data) {
  return request({
    url: '/user/sendcode',
    method: 'post',
    data: JSON.stringify(data)
  })
}
// 註冊
export function userRegister(data) {
  return request({
    url: '/user/register',
    method: 'post',
    data: JSON.stringify(data)
  })
}
//手机号登录
export function userPhoneLogin(data) {
  return request({
    url: '/user/phonelogin',
    method: 'post',
    data: JSON.stringify(data)
  })
}
//邮箱登录
export function userMailboxLogin(data) {
  return request({
    url: '/user/maiboxlogin',
    method: 'post',
    data: JSON.stringify(data)
  })
}

//用户信息

export function userInfo() {
  return request({
    url: `/user/queryuserinfo`,
    method: 'get',
  })
}

//修改用戶信息
export function Setuserinfo(data) {
  return request({
    url: '/user/updauserinfo',
    method: 'post',
    data: JSON.stringify(data)
  })
}

