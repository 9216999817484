import { userInfo } from "@/api/login";
import { getconfig } from "@/api/configBuy";
import { checkVipExpiry } from "@/api/packFun";
export default {
    namespaced: true,
    state: {

        user: null,
        userToken: null,
        isLoginShow: false, //是否展示login頁面
        generalization: '', //推广信息
        vipInfo: null, //会员信息
        maxTextareaLength: '300',//字数
        showWordLimit: true,
        vipExpir: {
            txtTip: '',
            isExpire: false
        },
        routerPath: '',
        isDark: false
    },
    mutations: {
        SET_EMPTY_DATA(state, params) {
            state.user = null;
            state.userToken = null;
            state.vipExpir.txtTip = '';
            state.vipExpir.isExpire = false;
        },
        SET_ISDARK(state, params) {
            state.isDark = params;
        },
        SET_ROUTERPATH(state, params) { //會員信息
            state.routerPath = params;
        },
        SET_VIPEXPIR(state, params) { //會員信息
            state.vipExpir = { ...state.vipExpir, ...params };
        },
        SET_VIPINFO(state, params) { //會員信息
            state.vipInfo = params;
        },
        SET_GENERALIZATION(state, params) { //推广
            state.generalization = params;
        },
        SET_SHOWWORDLIMIT(state, params) { //是否显示剩余字数
            state.showWordLimit = params;
        },
        SET_MAXTEXTAREALENGTH(state, params) { //字数
            if (params == 0) {
                state.maxTextareaLength = '300';
            } else if (params == 'infinite') {
                state.maxTextareaLength = Number.MAX_SAFE_INTEGER;
            }
            else {
                // let find = state.vipInfo.find((data) => data.id == params);
                // state.maxTextareaLength = find.text;
            }

        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_USER_TOKEN(state, token) {
            state.userToken = token;
        },
        SET_LOGIN_SHOW(state, show) {
            // console.log(show, 'mutations')
            state.isLoginShow = show;
        },

    },
    actions: {
        UPDATEROUTERPATH({ commit }, params) {
            commit('SET_ROUTERPATH', params);
        },

        async fetchUserInfo({ commit, state }) {
            try {
                const token = localStorage.getItem("token");
                if (token) {
                    commit('SET_USER_TOKEN', token);
                    let response = await userInfo();

                    if (response.code === 200 && response.conten) {
                        let user = response.conten[0]
                        commit('SET_USER', user);
                        localStorage.setItem("user", JSON.stringify(user));
                        if (user) {
                            let res = await getconfig();
                            if (res.code === 200 && res.conten) {

                                let generalization = res.conten[0].generalization.replace('{{id}}', user.id)
                                commit('SET_GENERALIZATION', generalization);
                                commit('SET_VIPINFO', res.conten[0].pcvip);
                                localStorage.setItem("generalization", JSON.stringify(res.conten[0].generalization));
                                localStorage.setItem("vipInfo", JSON.stringify(res.conten[0].pcvip));
                                //算力比率
                                localStorage.setItem("lntegralratio", JSON.stringify(res.conten[0].lntegralratio));
                            }
                            let { txtTip, isExpire } = await checkVipExpiry(user.pcviptimes);

                            /* if (isExpire || response.conten[0].viptype == 0 || response.conten[0].viptype == 1) { //過期或普通用戶
                                 // console.log('過期或普通用戶')
                                commit('SET_MAXTEXTAREALENGTH', '0');
                                commit('SET_SHOWWORDLIMIT', true)
                               
                                // 清緩存 
                                localStorage.removeItem("token");
                                localStorage.removeItem("user");
                                commit('SET_EMPTY_DATA')
                            } else {
                                if (response.conten[0].viptype == 2) {
                                    commit('SET_MAXTEXTAREALENGTH', user.viptype);
                                    commit('SET_SHOWWORDLIMIT', true)
                                    // console.log('手機專業版用戶')
                                } else {
                                     // console.log('網頁會員用戶')
                                    commit('SET_MAXTEXTAREALENGTH', 'infinite');
                                    commit('SET_SHOWWORDLIMIT', false)
                                }
                            } */
                            if (isExpire || response.conten[0].pcviptype == 0) { //未開通網頁會員 及過期會員
                                commit('SET_MAXTEXTAREALENGTH', '0');
                                commit('SET_SHOWWORDLIMIT', true)
                                // 清緩存 
                                localStorage.removeItem("token");
                                localStorage.removeItem("user");
                                commit('SET_EMPTY_DATA')
                            } else {
                                // console.log('網頁會員用戶')
                                commit('SET_MAXTEXTAREALENGTH', 'infinite');
                                commit('SET_SHOWWORDLIMIT', false)
                            }

                            commit('SET_VIPEXPIR', {
                                txtTip: txtTip,
                                isExpire: isExpire
                            });
                        } else {
                            commit('SET_MAXTEXTAREALENGTH', '0');
                            commit('SET_SHOWWORDLIMIT', true)
                        }

                    }
                } else {
                    commit('SET_USER_TOKEN', null);
                }
            } catch (e) {
                console.error("获取用户信息失败:", e);
            }
        },

    },
    getters: {
        showWordLimit: state => state.showWordLimit,
        isDark: state => state.isDark,
        vipExpir: state => state.vipExpir,
        isLoginShow: state => state.isLoginShow,
        user: state => state.user,
        userToken: state => state.userToken,
        vipInfo: state => state.vipInfo,
        generalization: state => state.generalization,
        maxTextareaLength: state => state.maxTextareaLength,
        routerPath: state => state.routerPath,

    },
};