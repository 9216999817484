
/**
 * 'videopath/' 
 * @param {*} dateTime appVIP过期时间
 * @returns 
 */
export function checkVipExpiryapp(dateTime) {

    // if (dateTime == null || dateTime == '') return;
    const expiryDate = new Date(dateTime);   // 将字符串转换为Date对象  
    const now = new Date();    // 获取当前日期    
    const diff = expiryDate - now;  // 计算时间差（毫秒）
    const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));  // 将时间差转换为天数 

    let vipExpir = {
        txtTip: '',
        isExpire: false
    }
    if (diffDays < 0) {   // 判断VIP是否到期  
        vipExpir.txtTip = Math.abs(diffDays);  // 如果到期了，返回过期了多少天  
        vipExpir.isExpire = true;
        // console.log('VIP已过期', vipExpir.txtTip)

    } else if (diffDays < 10) {
        vipExpir.txtTip = `VIP还有${diffDays}天到期`;
        vipExpir.isExpire = false;
        // console.log('VIP未过期')
    } else {
      
        vipExpir.txtTip = dateTime;
        vipExpir.isExpire = false;
        // console.log('如果到期了，返回过期了多少天')

    }
    // console.log(vipExpir)
    return vipExpir;
}  

export function checkVipExpiry(dateTime) {

    // if (dateTime == null || dateTime == '') return;
    const expiryDate = new Date(dateTime);   // 将字符串转换为Date对象  
    const now = new Date();    // 获取当前日期    
    const diff = expiryDate - now;  // 计算时间差（毫秒）
    const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));  // 将时间差转换为天数 

    let vipExpir = {
        txtTip: '',
        isExpire: false
    }
    if (diffDays < 0) {   // 判断VIP是否到期  
        vipExpir.txtTip = Math.abs(diffDays);  // 如果到期了，返回过期了多少天  
        vipExpir.isExpire = true;
        // console.log('VIP已过期', vipExpir.txtTip)

    } else if (diffDays < 10) {
        vipExpir.txtTip = `VIP还有${diffDays}天到期`;
        vipExpir.isExpire = false;
        // console.log('VIP未过期')
    } else {
      
        vipExpir.txtTip = dateTime;
        vipExpir.isExpire = false;
        // console.log('如果到期了，返回过期了多少天')

    }
    // console.log(vipExpir)
    return vipExpir;
}  