export default {
    namespaced: true,
    state: () => ({
      
    }),
    mutations: {
      
    },
    actions: {
      
    },
    getters: {
     
    },
  };